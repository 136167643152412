<template>
  <div class="col-12 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-list"></i>
          الباصات
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>العنوان</th>
              <th>خط السير</th>
              <th>التفاصيل</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="bus in busses" :key="bus._id">
                <td>
                  {{ bus.title }}
                </td>
                <td>
                  {{ groupName(bus.group_id) }}
                </td>
                <td>
                  {{ bus.details }}
                </td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="$router.push('/busses/add#' + bus._id)"
                  >
                    <i class="fa fa-edit"></i>
                    تعديل
                  </button>
                  &nbsp;
                  <button
                    class="btn text-danger btn-sm"
                    @click="deleteBus(bus)"
                  >
                    <i class="fa fa-trash"></i>
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BModal,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading: false,
      students: [],
      user: JSON.parse(localStorage.getItem("user")),
      busses: [],
      groups: {},
    };
  },
  created() {
    var g = this;
    if (!checkPer("busses-view")) {
      this.$router.push("/per");
      return false;
    }
    $.post(api + "/user/busses/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.busses = r.response;
      })
      .catch(function (e) {
        console.log(e);
        alert("حدث خطأ..");
      });
    $.post(api + "/user/groups/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        r.response.forEach((element) => {
          g.groups[element._id] = element;
        });
      })
      .catch(function (e) {
        console.log(e);
        alert("حدث خطأ..");
      });
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    deleteBus(bus) {
      var g = this;
      if (confirm("متأكد من حذف الباص نهائياً؟ ربما يؤثر على باقي السجلات")) {
        $.post(api + "/user/busses/delete", {
          jwt: g.user.jwt,
          id: bus._id,
        })
          .then(function (r) {
            location.reload();
          })
          .catch(function (e) {
            console.log(e);
            alert("حدث خطأ..");
          });
      }
    },
    groupName(id) {
      return this.groups[id] ? this.groups[id].title : "لا يوجد";
    },
  },
};
</script>